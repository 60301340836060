<template lang="pug">
campaign-save
</template>

<script>
import campaignSave from '@/components/campaignSave/index';
import {mapMutations} from 'vuex';
import {
	CLEAR_CALENDAR_DATA,
	CLEAR_CAMPAIGN,
	CLEAR_CAMPAIGN_STATE,
	CLEAR_CAMPAIGN_SURFACES,
} from '@/store/const/campaign';

export default {
	name: 'CampaignCreate',
	components: {
		campaignSave,
	},
	methods: {
		...mapMutations('Campaign', [
			CLEAR_CALENDAR_DATA,
			CLEAR_CAMPAIGN,
			CLEAR_CAMPAIGN_STATE,
			CLEAR_CAMPAIGN_SURFACES
		]),
	},
	mounted() {
		this.CLEAR_CAMPAIGN();
		this.CLEAR_CALENDAR_DATA();
		this.CLEAR_CAMPAIGN_STATE();
		this.CLEAR_CAMPAIGN_SURFACES();
	},
};
</script>
