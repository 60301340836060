<template lang="pug">
  v-container(fluid)
    v-row.pt-2
      v-col(cols='7')
        .split__content
          v-row
            v-col
              .field
                .field__container
                  .field__text Название РК
                  v-text-field#campaign-name(
                    label='Название РК',
                    v-model='mutableCampaign.name',
                    dense,
                    outlined,
                    hide-details='auto'
                  )
          v-row
            v-col
              .field
                .field__container
                  .field__text Группа РК
                  v-combobox(
                    clearable,
                    v-model='mutableCampaign.group',
                    :items='getGroups',
                    dense,
                    outlined,
                    hide-details
                  )
          v-row(justify='center')
            v-col(cols='12')
              .field__calendar(style='display: flex; align-items: center')
                .field.mr-auto(style='width: 90%')
                  .field__container
                    .field__text Период
                    .d-flex.align-center
                      .date-width(:class="{ picker_disabled: isDisabled }")
                        v-menu(
                          ref='menu_from',
                          v-model='menu_from',
                          :close-on-content-click='false',
                          transition='scale-transition',
                          offset-y,
                          min-width='auto',
                          :disabled='isDisabled'
                        )
                          v-date-picker(
                            :range="!isDisabled"
                            header-color='accent',
                            v-model='isDisabled ? date[0] : date',
                            :min='new Date().toISOString().substr(0, 10)',
                            show-adjacent-months,
                            first-day-of-week='1',
                            locale='ru-ru'
                          )
                          template(v-slot:activator='{ on, attrs }')
                            v-text-field(
                              style='margin-left: -3px',
                              v-model='isDisabled ? dateFormatted[0] : dateFormatted',
                              readonly='',
                              v-bind='attrs',
                              v-on='on',
                              dense,
                              outlined,
                              hide-details
                            )
                      .d-flex.align-center.mx-2(v-if="isDisabled")
                        v-tooltip(top)
                          template( v-slot:activator="{ on, attrs }" )
                            v-icon(v-bind="attrs" v-on="on") mdi-alert-circle-outline
                          span Изменить дату старта кампании невозможно, так как по ней есть статистика
                      template(v-if="isDisabled")
                        .d-flex.align-center.mx-2 -
                        .date-width
                          v-menu(
                            ref='menu_to',
                            v-model='menu_to',
                            :close-on-content-click='false',
                            transition='scale-transition',
                            offset-y,
                            min-width='auto',
                          )
                            v-date-picker(
                              header-color='accent',
                              v-model='date[1]',
                              :min='new Date().toISOString().substr(0, 10)',
                              show-adjacent-months,
                              first-day-of-week='1',
                              locale='ru-ru'
                            )
                            template(v-slot:activator='{ on, attrs }')
                              v-text-field(
                                style='margin-left: -3px, width: 15px',
                                v-model='isDisabled ? dateFormatted[1] : dateFormatted',
                                readonly='',
                                v-bind='attrs',
                                v-on='on',
                                dense,
                                outlined,
                                hide-details
                              )
                .mx-6.pl-2(
                  style='display: flex; align-items: center; cursor: pointer; border-bottom: 1px dashed #4a55b0; font-size: 12px; white-space: nowrap',
                  @click='openCalendar'
                )
                  | Настройка периода открутки
                  v-icon chevron_right
                calendar(:dialog='dialog', @onClose='dialog = false')
          v-row
            v-col
              .field
                .field__container
                  .field__text Закупка
                  v-select(
                    v-model='mutableCampaign.bidType',
                    :items='getBidTypes',
                    item-value='id',
                    item-text='name',
                    :menu-props='{ bottom: true, offsetY: true }',
                    dense,
                    outlined,
                    hide-details,
                    :key='"bidType" + componentKey',
                    append-icon='mdi-chevron-down',
                    @change='changeBidType'
                  )
            v-col
              .field
                .field__container
                  .field__text Стратегия
                  v-select(
                    v-model='mutableCampaign.bidStrategy',
                    :items='getBidStrategies',
                    item-value='id',
                    item-text='name',
                    :menu-props='{ bottom: true, offsetY: true }',
                    dense,
                    outlined,
                    key='bidStrategy',
                    hide-details,
                    append-icon='mdi-chevron-down',
                    @change='onStrategyChange'
                  )
          v-row
            v-col(cols='6')
              .field
                .field__container
                  .field__text Тип открутки
                  v-select#campaign-buyingType-list(
                    v-model='mutableCampaign.buyingType',
                    :items='getBuyingTypes',
                    item-value='id',
                    item-text='name',
                    :menu-props='{ bottom: true, offsetY: true }',
                    dense,
                    outlined,
                    hide-details,
                    append-icon='mdi-chevron-down',
                  )
            v-col(cols='6' v-if='mutableCampaign.buyingType === "timeouts"')
              .field
                .field__container
                  .field__text.ml-2 Частота показа
                  v-row
                    v-col
                      v-text-field(
                        v-model='timeouts.hour',
                        type='number',
                        label='Час'
                        dense,
                        outlined,
                        hide-details='auto',
                      )
                    v-col
                      v-text-field(
                        v-model='timeouts.minute'
                        type='number',
                        label='Мин'
                        dense,
                        outlined,
                        hide-details='auto',
                      )
                    v-col
                      v-text-field(
                        v-model='timeouts.second'
                        type='number',
                        label='Сек'
                        dense,
                        outlined,
                        hide-details='auto',
                      )
                    .timeout_tooltip
                      v-tooltip(top)
                        template( v-slot:activator="{ on, attrs }" )
                          v-icon(v-bind="attrs" v-on="on") mdi-alert-circle-outline
                        span Ограничения выхода креатива, на одной поверхности

          v-row(v-if='getStrategiesMode === "fixPrice"')
            .mx-3
              .field
                v-row.my-1
                  .text Бюджет
                  v-switch.switch-custom(
                    v-model='isBudget',
                    hide-details,
                    color='accent',
                    style='margin: 0'
                  )
                  .text {{ mutableCampaign.bidType === 'show' ? 'Показы' : 'Рейтинг' }}
          v-row
            v-col
              .field
                .field__container(
                  :class='{ "field__text--disabled": isBudget && getStrategiesMode === "fixPrice" }'
                )
                  .field__text(:class='{ "field__text--decor": mutableCampaign.bidType === "show" }') Бюджет
                  v-row
                    v-col
                      v-text-field(
                        label='Всего',
                        v-model='mutableCampaign.limit.totalBudget',
                        dense,
                        outlined,
                        :readonly='isBudget && getStrategiesMode === "fixPrice"',
                        :class='{ "c-not-allowed": isBudget }',
                        hide-details='auto',
                        type='number',
                        @input='onInput'
                      )
                  v-row(v-if='mutableCampaign.bidType === "show"')
                    v-col
                      v-text-field(
                        label='В день',
                        v-model='mutableCampaign.limit.dayBudget',
                        dense,
                        outlined,
                        :readonly='isBudget && getStrategiesMode === "fixPrice"',
                        :class='{ "c-not-allowed": isBudget }',
                        hide-details='auto',
                        type='number',
                        @input='onInput'
                      )
            v-col
              .field
                .field__container(
                  :class='{ "field__text--disabled": !isBudget && getStrategiesMode === "fixPrice" }'
                )
                  .field__text(:class='{ "field__text--decor": mutableCampaign.bidType === "show" }') {{ mutableCampaign.bidType === 'show' ? 'Показы' : 'Рейтинг (TRP)' }}
                  v-row
                    v-col
                      v-text-field(
                        label='Всего',
                        v-model='mutableCampaign.limit.totalShow',
                        dense,
                        :value="ratingTRP"
                        outlined,
                        :readonly='!isBudget && getStrategiesMode === "fixPrice"',
                        :class='{ "c-not-allowed": !isBudget }',
                        hide-details='auto',
                        type='number',
                        @input='onInput'
                      )
                  v-row(v-if='mutableCampaign.bidType === "show"')
                    v-col
                      v-text-field(
                        label='В день',
                        v-model='mutableCampaign.limit.dayLoad',
                        dense,
                        outlined,
                        :readonly='!isBudget && getStrategiesMode === "fixPrice"',
                        :class='{ "c-not-allowed": !isBudget }',
                        hide-details='auto',
                        type='number',
                        @input='onInput'
                      )
          v-row
            v-col(cols='6', v-if='getStrategiesMode === "fixPrice"')
              .field
                .field__container
                  .field__text(style='max-width: 90px') {{ mutableCampaign.bidType === 'show' ? 'Ставка' : 'CPP (цена за пункт рейтинга)' }}
                  v-text-field(
                    type='number',
                    label='Цена',
                    v-model='mutableCampaign.cpm',
                    dense,
                    outlined,
                    hide-details='auto',
                    @input='onInput'
                  )
            v-col(cols='6' v-if='getStrategiesMode === "fixPrice" && getUserInfo.role === "Root"')
              .field
                .field__container.d-flex.align-center
                  .field__text Наценка %
                  v-text-field(
                    style='width: 100px',
                    v-model.number='raisingCofFixPrice',
                    dense,
                    :hide-details='true',
                    type='number',
                    :step='1',
                    single-line,
                    outlined
                  )
            v-col(cols='12' v-if='getStrategiesMode === "minCPM"')
              .mx-3
                .field
                  v-row.my-1
                    .text Общая наценка
                    v-switch.switch-custom.ma-0(
                      v-model='isActivePublishers',
                      @change='changeRaisingCof',
                      hide-details,
                      color='accent'
                    )
                    .text Наценка по поставщикам
            v-col(cols='6', v-if='getStrategiesMode === "minCPM"')
              .field
                .field__container.d-flex.align-center(:class='{ "field__text--disabled": isActivePublishers }')
                  .field__text Общая наценка%
                  v-text-field(
                    style='width: 100px',
                    v-model.number='mutableCampaign.raisingCof',
                    :menu-props='{ bottom: true, offsetY: true }',
                    dense,
                    :hide-details='true',
                    type='number',
                    :step='1',
                    single-line,
                    outlined,
                    :disabled='isActivePublishers',
                    @input='onInputPublisher'
                  )
                    template(#append)
                      .arrow
                        .arrow__button.arrow__button(@click='mutableCampaign.raisingCof += 10')
                        .arrow__button.arrow__button_bottom(
                          @click='mutableCampaign.raisingCof -= 10'
                        )
            transition(name='fade')
              v-col.pt-0(cols='6', v-if='getStrategiesMode === "minCPM" && isActivePublishers')
                .d-flex.align-center(v-for='item in isPublishers' :class='{ "field__text--disabled": !isActivePublishers }')
                  v-col(cols='6' lg='6', xl='4')
                    .fz-12 {{publisherName(item)}}
                  v-col(cols='6', lg='6', xl='8')
                    v-text-field(
                      v-model.number='raisingCofPub[item]',
                      dense,
                      :hide-details='true',
                      type='number',
                      single-line,
                      outlined,
                      :disabled='!isActivePublishers'
                      @input='onInputPublisher'
                    )
          v-row(v-if='getStrategiesMode === "minCPM"')
            v-col
              .estimated_cost
                | Ориентировочная стоимость выхода: &nbsp;
                span Мин.&nbsp;
                span.under {{ estimatedMinCost }}
                span &nbsp;&nbsp;Сред.&nbsp;
                span.under {{ estimatedCost }}
                span &nbsp;&nbsp;Макс.&nbsp;
                span.under {{ estimatedMaxCost }}
          v-row
            v-col.pl-16.ml-7
              v-btn(
                @click='save',
                elevation='0',
                color='accent',
                :loading='isLoading'
                :disabled='isLoading'
              ) {{ this.$route.params.id ? 'Сохранить кампанию' : 'Создать кампанию' }}

      v-col(cols='5')
        calculate-efficiency(@getRatingTRP="getRatingTRP" :visibleRatingTRP="isActiveRatingTRP" @getTotalBudget="getTotalBudget")
      v-col
        .d-flex
          span.pl-4 Выбрано поверхностей: {{getSelectedSurfaces.length}}
          export_excel(:data="getSelectedSurfaces")
        surfaces.my-8
    v-row
      v-col
        div(style='font-size: 12px; margin: 60px 0 0')
          b Примечание
          p(style='margin: 1em 0 0') Если в рекламной кампании используются поверхности разного типа от разных поставщиков поверхностей, то в рекламной кампании должен быть добавлен хотя бы 1 ролик, который будет удовлетворять техническим требованиям поставщика для данного типа поверхности. В ином случае на этих поверхностях не будет происходить открутка креативов
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import calendar from './calendar';
import uploadCsvList from './uploadCsvList';
import CalculateEfficiency from './calculateEfficiency';
import surfaces from '@/components/campaignSave/surfaces';
import Export_excel from '@/components/export_excel';
import loading from '@/mixins/loading_mixin';
import {GET_FORECAST} from '@/store/const/report';
import {
  CLEAR_CALENDAR_DATA,
  GET_CAMPAIGN_STATE,
  GET_CAMPAIGN_STRATEGIES,
  SET_CURRENT_CAMPAIGN,
  SET_DATE,
  CREATE_CAMPAIGN,
  EDIT_CAMPAIGN,
  SET_CAMPAIGN_STRATEGY
} from '@/store/const/campaign';

export default {
  name: 'campaignSaveMain',
  components: {
    Export_excel,
    CalculateEfficiency,
    calendar,
    uploadCsvList,
    surfaces
  },
  mixins: [loading],
  data() {
    return {
      timeouts: {
        hour: '',
        minute: '',
        second: '',
      },
      date: [],
      menu_from: false,
      menu_to: false,
      rating: 0,
      name: '',
      dialog: false,
      isBudget: false,
      mutableCampaign: {
        limit: {
          totalBudget: 0
        }
      },
      componentKey: 0,
      avrg: 0,
      raisingCofFixPrice: 10,
      raisingCofPub: {},
      isActivePublishers: false,
      ratingTRP: ''
    };
  },
  watch: {
    mutableCampaign: {
      deep: true,
      handler(value) {
        this.SET_CURRENT_CAMPAIGN(value)
      },
    },
    timeouts: {
      handler() {
        this.mutableCampaign.timeoutShow = this.calculateTimeoutSeconds()
        this.SET_CURRENT_CAMPAIGN(this.mutableCampaign)
      },
      deep: true,
    },
  },
  async created() {
    await this.GET_CAMPAIGN_STATE();
    await this.GET_CAMPAIGN_STRATEGIES()
    this.mutableCampaign = this.getCampaign;
    if (!this.mutableCampaign.bidType) {
      this.mutableCampaign.bidType = 'rating';
    }
    if (!this.mutableCampaign.limit) {
      this.mutableCampaign.limit = {
        totalBudget: 0,
      };
    }
    if (this.mutableCampaign.bidType === 'rating') {
      this.mutableCampaign.limit.totalShow = this.mutableCampaign.limit.trp;
    }
    if (this.mutableCampaign.bidStrategy === 'minCPM') {
      await this.onStrategyChange(this.mutableCampaign.bidStrategy);
    }
    if (this.mutableCampaign && this.mutableCampaign.bidStrategy) {
      this.mutableCampaign.bidStrategy = this.getCampaign.bidStrategy
      this.SET_CAMPAIGN_STRATEGY(this.mutableCampaign.bidStrategy)
    } else {
      const bidStrategy = this.getBidStrategies.find(item => item.id === 'minCPM').id
      this.mutableCampaign.bidStrategy = bidStrategy
      this.SET_CAMPAIGN_STRATEGY(bidStrategy)
    }
    if (this.mutableCampaign.raisingCofPub && Object.keys(this.mutableCampaign.raisingCofPub).length > 0) {
      this.isActivePublishers = true
    }
    setTimeout(() => {
      this.calculateDate();
      this.secondsToHours(this.mutableCampaign.timeoutShow);
    }, 500);
  },
  computed: {
    ...mapGetters('User', ['getUserInfo']),
    ...mapGetters('Report', ['getAnalytics']),
    ...mapGetters('Campaign', ['getSelectedSurfaces', 'getStrategiesMode']),
    ...mapGetters('Map', ['getPublishers']),
    ...mapGetters('Inventory', ['getInventoryList']),
    ...mapGetters('Campaign', [
      'getCampaign',
      'getGroups',
      'getBidTypes',
      'getBuyingTypes',
      'getCampaignState',
      'getBidStrategies'
    ]),
    dateFormatted() {
      let total = ''
      let dates = []
      if (!this.date.length) {
        return total
      }
      if (this.date.length && this.isDisabled) {
        dates.push(this.$moment(this.date[0]).format('DD.MM.YYYY'))
        dates.push(this.$moment(this.date[1]).format('DD.MM.YYYY'))

        this.mutableCampaign.periodFrom = this.$moment(this.date[0]).startOf('day').valueOf();
        this.mutableCampaign.periodTo = this.$moment(this.date[1]).endOf('day').valueOf();

        return dates
      } else {
        total += this.date[0] < this.date[1]
          ? this.$moment(this.date[0]).format('DD.MM.YYYY')
          : this.$moment(this.date[1]).format('DD.MM.YYYY');

        total += ' - '

        total += this.date[0] < this.date[1]
          ? this.$moment(this.date[1]).format('DD.MM.YYYY')
          : this.$moment(this.date[0]).format('DD.MM.YYYY');

        this.mutableCampaign.periodFrom = this.$moment(this.date[0]).startOf('day').valueOf();
        this.mutableCampaign.periodTo = this.$moment(this.date[1]).endOf('day').valueOf();
        return total;
      }
    },
    isRatingError() {
      return this.getSelectedSurfaces.filter((i) => i.rating < this.mutableCampaign.rating).length;
    },
    estimatedCost() {
      if (this.avrg === 0) {
        return this.avrg;
      }
      if (this.mutableCampaign.raisingCof === 0) {
        return this.avrg.toFixed(2);
      }
      const result = this.avrg + (this.avrg / 100) * this.mutableCampaign.raisingCof;
      return result.toFixed(2);
    },
    estimatedMinCost() {
      if (this.getAnalytics.length > 0) {
        let min = Math.min.apply(Math, this.getAnalytics.map(o => o.avgBidFloor))
        if (this.mutableCampaign.raisingCof === 0) {
          return min.toFixed(2)
        }
        return (min + (min / 100) * this.mutableCampaign.raisingCof).toFixed(2);
      }
      return 0;
    },
    estimatedMaxCost() {
      if (this.getAnalytics.length > 0) {
        let max = Math.max.apply(Math, this.getAnalytics.map(o => o.avgBidFloor));
        if (this.mutableCampaign.raisingCof === 0) {
          return max.toFixed(2)
        }
        return (max + (max / 100) * this.mutableCampaign.raisingCof).toFixed(2)
      }
      return 0;
    },
    isDisabled() {
      return this.getCampaignState.bid > 0 || (this.mutableCampaign.state && this.mutableCampaign.state.bid) > 0;
    },
    isPublishers() {
      const duplicatesPublishers = this.getSelectedSurfaces.map(item => item.publisherId)
      return duplicatesPublishers.filter((item, index) => {
        this.raisingCofPub[item] = this.mutableCampaign
          && this.mutableCampaign.raisingCofPub
          && this.mutableCampaign.raisingCofPub[item]
        return duplicatesPublishers.indexOf(item) === index;
      })
    },
    isActiveRatingTRP() {
      return !this.isBudget && this.getStrategiesMode === "fixPrice"
    }
  },
  mounted() {
    this.setLoadingActions()
  },
  methods: {
    ...mapActions('Report', [GET_FORECAST]),
    ...mapActions('Campaign', [GET_CAMPAIGN_STATE, GET_CAMPAIGN_STRATEGIES, EDIT_CAMPAIGN, CREATE_CAMPAIGN]),
    ...mapMutations('Campaign', [SET_CURRENT_CAMPAIGN, SET_DATE, CLEAR_CALENDAR_DATA, SET_CAMPAIGN_STRATEGY]),
    setLoadingActions() {
      this.actions = [
        EDIT_CAMPAIGN,
        CREATE_CAMPAIGN
      ]
    },
    openCalendar() {
      this.dialog = true;
      this.SET_DATE(this.date.sort());
    },
    save() {
      if (this.mutableCampaign.bidType === 'rating') {
        this.mutableCampaign.limit.trp = this.mutableCampaign.limit.totalShow;
        delete this.mutableCampaign.limit.totalShow;
        this.SET_CURRENT_CAMPAIGN(this.mutableCampaign);
      } else {
        delete this.mutableCampaign.limit.trp;
        this.SET_CURRENT_CAMPAIGN(this.mutableCampaign);
      }
      this.$emit('save', {data: this.raisingCofPub, switchRaisingCof: this.isActivePublishers});
    },
    onInput() {
      if (this.mutableCampaign.bidStrategy === 'minCPM') {
        return;
      }
      if (this.isBudget) {
        this.mutableCampaign.limit.totalBudget = Math.floor(
          this.mutableCampaign.limit.totalShow * this.mutableCampaign.cpm,
        );
        if (this.mutableCampaign.bidType === 'show') {
          this.mutableCampaign.limit.dayBudget = Math.floor(
            this.mutableCampaign.limit.dayLoad * this.mutableCampaign.cpm,
          );
        }
      } else {
        this.mutableCampaign.limit.totalShow = Math.floor(
          this.mutableCampaign.limit.totalBudget / this.mutableCampaign.cpm,
        );
        if (this.mutableCampaign.bidType === 'show') {
          this.mutableCampaign.limit.dayLoad = Math.floor(
            this.mutableCampaign.limit.dayBudget / this.mutableCampaign.cpm,
          );
        }
      }
      this.SET_CURRENT_CAMPAIGN(this.mutableCampaign);
    },
    changeBidType(val) {
      this.$set(this.mutableCampaign, 'bidType', val);
      this.forceRerender();
      this.SET_CURRENT_CAMPAIGN(this.mutableCampaign);
    },
    async onStrategyChange(val) {
      if (val === 'fixPrice') {
        this.SET_CAMPAIGN_STRATEGY(val)
        return
      }
      if (val === 'minCPM') {
        this.SET_CAMPAIGN_STRATEGY(val)
      }
      this.mutableCampaign.cpm = 0;
      if (this.getSelectedSurfaces.length === 0) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Нет выбранных поверхностей',
        });
        return;
      }
      let from = this.$moment.utc().subtract(7, 'days').startOf('day').valueOf()
      let to = this.$moment.utc().endOf('day').valueOf()
      const ids = this.getSelectedSurfaces.map((marker) => marker.id);
      let params = {
        filter: [
          {
            field: 'surface_id',
            operation: '=',
            values: ids,
          },
        ],
        from,
        to,
        group: ['surface_id'],
        limit: null,
      };
      try {
        await this.GET_FORECAST(params);
        if (this.getAnalytics.length > 0) {
          const sum = this.getAnalytics.reduce((a, b) => a + b.avgBidFloor, 0);
          this.avrg = sum / this.getAnalytics.length;
        }
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: e.error.message,
        });
      }
    },
    calculateDate() {
      if (this.$route.params.id && this.mutableCampaign.calendar)
        this.date = [
          new Date(this.mutableCampaign.calendar.dates[0].from),
          new Date(this.mutableCampaign.calendar.dates[0].to),
        ];
      else this.date = [new Date(), new Date(new Date().setDate(new Date().getDate() + 7))];

      let date = this.date[0],
        dd = date.getDate(),
        mm = date.getMonth() + 1,
        yy = date.getFullYear();
      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;
      if (yy < 10) yy = '0' + yy;

      let date2 = this.date[1],
        dd2 = date2.getDate(),
        mm2 = date2.getMonth() + 1,
        yy2 = date2.getFullYear();
      if (dd2 < 10) dd2 = '0' + dd2;
      if (mm2 < 10) mm2 = '0' + mm2;
      if (yy2 < 10) yy2 = '0' + yy2;
      this.date = [`${yy}-${mm}-${dd}`, `${yy2}-${mm2}-${dd2}`];
    },
    forceRerender() {
      this.componentKey += 1;
    },
    secondsToHours(secs) {
      let hours = Math.floor(secs / (60 * 60));

      let divisor_for_minutes = secs % (60 * 60);
      let minutes = Math.floor(divisor_for_minutes / 60);

      let divisor_for_seconds = divisor_for_minutes % 60;
      let seconds = Math.ceil(divisor_for_seconds);

      this.timeouts.hour = hours === 0 ? '' : hours;
      this.timeouts.minute = minutes === 0 ? '' : minutes;
      this.timeouts.second = seconds === 0 ? '' : seconds;
    },
    calculateTimeoutSeconds() {
      let hour = parseInt(this.timeouts.hour) || 0;
      let minute = parseInt(this.timeouts.minute) || 0;
      let second = parseInt(this.timeouts.second) || 0;
      return (+hour) * 60 * 60 + (+minute) * 60 + (+second)
    },
    publisherName(item) {
      const publisher = this.getPublishers.find(a => a.id === item)
      return publisher ? publisher.name : item
    },
    onInputPublisher() {
      const values = Object.values(this.raisingCofPub)
      if (this.isActivePublishers) {
        this.mutableCampaign.raisingCof = Math.min(...values) || Math.max(...values)
      }
    },
    changeRaisingCof() {
      if (!this.isActivePublishers) {
        this.raisingCofPub = {}
        this.mutableCampaign.raisingCofPub = {}
        this.mutableCampaign.raisingCof = 0
      } else {
        this.mutableCampaign.raisingCof = 0
      }
    },
    getRatingTRP(data) {
      this.ratingTRP = parseFloat(data.ratingTRP).toFixed(2)
      this.mutableCampaign.limit.totalShow = this.ratingTRP
      this.SET_CURRENT_CAMPAIGN(this.mutableCampaign)
    },
    getTotalBudget(number) {
      this.mutableCampaign.limit.totalBudget = number.toFixed(2)
    },
  },
};
</script>

<style scoped lang="scss">
.file-input {
  margin-right: 10px !important;

  & .v-file-input__text {
    cursor: pointer !important;
  }

  & .v-input__slot {
    background-color: #485caa !important;
    box-shadow: none !important;

    & label {
      font-size: 0.875rem !important;
      color: #fff !important;
      font-weight: bold;
      left: 50% !important;
      transform: translateX(-50%);
    }

    & .v-file-input__text {
      color: #fff !important;
    }
  }

  & .v-input__append-inner {
    margin-left: -15px !important;
  }
}

.text {
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  color: #737373;
  margin: auto 20px auto 10px;
}

.c-not-allowed input {
  cursor: not-allowed !important;
}

.switch-custom .v-input--selection-controls__input div {
  color: #4a55b0 !important;
}

.loading {
  left: 50% !important;
  bottom: -50px !important;
}

.split__item {
  width: 60%;
}

.split__item:last-child {
  width: 40%;
}

.estimated_cost {
  font-family: Gilroy;
  font-size: 14px;
  padding-left: 85px;
}

.estimated_cost span.under {
  text-decoration: underline;
}

.date-width.picker_disabled {
  opacity: 0.5;
}

.date-width {
  width: 180px;
}

.timeout_tooltip {
  display: flex;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
