<template lang="pug">
  v-container(fluid)
    .split
      .split__header
        .split__title.split__title_one-word(@click='map = true', :class='{ active: map }') Инвентарь
      .split__header
        .split__title(@click='map = false', :class='{ active: !map }') Настройки РК
    .loading(v-if='isLoading', style='height: 400px')
    .split-main(v-else)
      .split-main__item.split-main__item--absolute(v-if='!map', :class='{ active: !map }')
        campaignSaveMain(
          @save='save'
        )
      campaign-save-map.split-main__item(
        v-if='map',
        :class='{ active: map }',
        :visible='map',
        @map='map = $event'
        @popupImages="getImageSrc"
      )
    popupImage(:srcImg="srcImg")
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import campaignSaveMain from './mainData';
import campaignSaveMap from './map';
import popupImage from '@/components/popupImage';
import loading_mixin from '@/mixins/loading_mixin';
import {formatRquestStatsData} from '@/mixins/date_mixin';
import {GET_INVENTORY_LIST} from '@/store/const/inventory';
import {SET_CURRENT_CITY} from '@/store/const/map';
import {
  BUYING_TYPES,
  CLEAR_CALENDAR_DATA,
  CLEAR_CAMPAIGN,
  CLEAR_CAMPAIGN_STATE,
  CLEAR_CAMPAIGN_SURFACES,
  CREATE_CAMPAIGN,
  EDIT_CAMPAIGN,
  GET_BID_TYPES, GET_CAMPAIGN,
  GET_CAMPAIGN_LIST,
  SET_CAMPAIGN_STRATEGY,
} from '@/store/const/campaign';

export default {
  name: 'campaignSave',
  components: {
    campaignSaveMap,
    campaignSaveMain,
    popupImage
  },
  mixins: [formatRquestStatsData, loading_mixin],
  data() {
    return {
      map: true,
      srcImg: ''
    };
  },
  computed: {
    ...mapGetters('Campaign', ['getCampaign', 'getSelectedSurfaces', 'getWeekDays']),
    ...mapGetters('Map', ['getUnits']),
  },
  async mounted() {
    let data = {
      status: 'all',
      type: 'all',
      datestart: this.$moment().subtract(7, 'days').startOf('day').valueOf(),
      datestop: this.$moment().endOf('day').valueOf()
    };
    try {
      await this.GET_CAMPAIGN_LIST(data);
      await this.GET_BID_TYPES();
      await this.BUYING_TYPES();
      await this.GET_INVENTORY_LIST();
    } catch (e) {
      console.error('error in fetch data');
    }
  },
  beforeDestroy() {
    this.CLEAR_CAMPAIGN();
    this.CLEAR_CALENDAR_DATA();
    this.CLEAR_CAMPAIGN_STATE();
    this.CLEAR_CAMPAIGN_SURFACES();
    this.SET_CURRENT_CITY('MSK');
    this.SET_CAMPAIGN_STRATEGY('')
  },
  methods: {
    ...mapActions('Campaign', [
      GET_CAMPAIGN_LIST,
      GET_BID_TYPES,
      BUYING_TYPES,
      CREATE_CAMPAIGN,
      EDIT_CAMPAIGN,
      GET_CAMPAIGN
    ]),
    ...mapActions('Inventory', [GET_INVENTORY_LIST]),
    ...mapMutations('Map', [SET_CURRENT_CITY]),
    ...mapMutations('Campaign', [CLEAR_CALENDAR_DATA, CLEAR_CAMPAIGN, CLEAR_CAMPAIGN_STATE, CLEAR_CAMPAIGN_SURFACES, SET_CAMPAIGN_STRATEGY]),
    errorShow(message) {
      this.$notify({
        type: 'error',
        title: 'Ошибка',
        text: message,
      });
    },
    valid() {
      if (!this.getCampaign.name) {
        this.errorShow('Введите название кампании');
        return false;
      } else if (this.getCampaign.name.length < 3) {
        this.errorShow('Название кампании дожно быть не менее 3-х символов');
        return false;
      }
      if (!this.getSelectedSurfaces.length && !this.getCampaign.surfaces.length) {
        this.errorShow('Вы не выбрали инвентарь');
        return false;
      }
      if (!this.getCampaign.bidType) {
        this.errorShow('Вы не выбрали стратегию');
        return false;
      }
      if (!this.getCampaign.buyingType) {
        this.errorShow('Вы не выбрали тип открутки');
        return false;
      }
      const need_cpm = this.getCampaign.bidStrategy && this.getCampaign.bidStrategy === 'fixCPM';
      if ((!this.getCampaign.cpm && need_cpm) || (!this.getCampaign.cpm && !this.getCampaign.bidStrategy)) {
        this.errorShow('Укажите стоимость показа');
        return false;
      }
      if (this.getCampaign.buyingType === 'timeouts' && (!this.getCampaign.timeoutShow || this.getCampaign.timeoutShow === 0)) {
        this.errorShow('Вы выбрали тип открутки комбинированный, необходимо указать и частоту показа');
        return false;
      }
      return true;
    },
    prepareData() {
      let data = {
        name: this.getCampaign.name,
        cpm: Number(this.getCampaign.cpm),
        raisingCof: Number(this.getCampaign.raisingCof),
        limit: {
          totalBudget: Number(this.getCampaign.limit.totalBudget),
          dayBudget: Number(this.getCampaign.limit.dayBudget),
          dayLoad: Number(this.getCampaign.limit.dayLoad),
        },
        calendar: {
          dates: [
            {
              from: this.$moment(this.getCampaign.periodFrom).toISOString(true),
              to: this.$moment(this.getCampaign.periodTo).toISOString(true),
            },
          ],
          week_days: this.getWeekDays,
        },
        audience: {
          name: '',
          targetings: [],
        },
        surfaces: this.getSelectedSurfaces.map((a) => a.id),
        group: this.getCampaign.group,
        buyingType: this.getCampaign.buyingType,
        bidType: this.getCampaign.bidType,
        bidStrategy: this.getCampaign.bidStrategy,
        timeoutShow: this.getCampaign.timeoutShow
      };
      if (this.getCampaign.bidType === 'rating') {
        data.limit.trp = Number(this.getCampaign.limit.trp);
      } else {
        data.limit.totalShow = Number(this.getCampaign.limit.totalShow);
      }
      let no_audience =
        !this.getCampaign.audience.sex.length &&
        !this.getCampaign.audience.income.length &&
        !this.getCampaign.audience.ageFrom &&
        !this.getCampaign.audience.ageTo;
      let is_empty = this.getCampaign.bidType === 'show';

      // Если создание, кампания по паказам или не заполнена аудитория, то передаем определенные значения
      if (!this.$route.params.id && (is_empty || no_audience)) {
        data.audience.ageFrom = 18;
        data.audience.ageTo = 99;
        data.audience.sex = 'all';
        data.audience.income = 'abc';
      } else {
        data.audience.ageFrom = parseInt(this.getCampaign.audience.ageFrom) || 0;
        data.audience.ageTo = parseInt(this.getCampaign.audience.ageTo) || 99;
        data.audience.income = this.getCampaign.audience.income.length
          ? this.getCampaign.audience.income.sort().join('')
          : 'abc';
        data.audience.sex = this.getCampaign.audience.sex.length !== 1 ? 'all' : this.getCampaign.audience.sex[0];
      }
      return data;
    },
    async save(params) {
      if (!this.valid()) return;
      let data = this.prepareData();
      data.raisingCofPub = {}
      if (params.switchRaisingCof) {
        data.raisingCofPub = params.data
      }
      if (!params.switchRaisingCof && !this.getCampaign.raisingCof && data.bidStrategy === 'minCPM') {
        this.errorShow('Заполните общую наценку');
        return false
      }
      if (params.switchRaisingCof && Object.keys(params.data).find(key => (params.data[key] === undefined || params.data[key] === '') || params.data[key] === 0)) {
        this.errorShow('Заполните наценку для каждого поставщика');
        return false
      }
      if (this.$route.params.id) {
        data.id = this.getCampaign.id;
        data.audience.id = this.getCampaign.audienceID;
        await this.EDIT_CAMPAIGN(data)
        if (this.isError(EDIT_CAMPAIGN)) {
          this.$notify({
            type: 'error',
            title: '',
            text: this.errorMessage(EDIT_CAMPAIGN),
          });
          return;
        }
        this.$notify({
          type: 'success',
          title: '',
          text: 'Кампания успешно изменена',
        });
        await this.$router.push('/radar/campaign/' + data.id);
      } else {
        const result = await this.CREATE_CAMPAIGN(data)
        if (this.isError(CREATE_CAMPAIGN)) {
          this.errorShow(this.errorMessage(CREATE_CAMPAIGN))
          return;
        }
        this.$notify({
          type: 'success',
          title: '',
          text: 'Кампания успешно создана',
        });
        await this.$router.push('/radar/campaign/' + result.data.id);
      }
    },
    getImageSrc(image) {
      this.srcImg = image
    }
  },
};
</script>

<style lang="scss" scoped>
.split-main {
  position: relative;
  width: 100%;
}

.split-main__item {
  &--absolute {
    position: absolute;
    top: 0;
    width: 100%;
  }

  &:not(.active) {
    opacity: 0;
    pointer-events: none;
  }
}

.refresh-button {
  padding: 0 !important;
  min-width: 40px !important;
}

.split {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #d6d6d6;

  :last-child &__header {
    padding-right: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translateY(1px);
  }

  &__header-old {
    width: 50%;
  }

  &__title {
    height: 100%;
    font-size: 16px;
    font-weight: bold;
    padding: 20px 60px 25px;
    border-bottom: 2px solid transparent;
    display: inline-block;
    cursor: pointer;
    transition: 0.2s ease-out;

    &:hover {
      border-color: #d6d6d6;
    }

    &.active {
      border-color: #4a55b0;
    }
  }
}
</style>

<style lang="scss">
.info-block {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  line-height: 1.1;
}

.card {
  &__wrapper {
    position: relative;
    z-index: 1;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
    margin-top: 25px;

    &.is-error {
      box-shadow: 0 2px 10px rgba(255, 0, 0, 0.47);
    }
  }

  background: #fff;
  padding: 10px;

  &__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 10px;
  }

  &__text {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    color: #737373;
  }

  &__del {
    position: absolute;
    top: 10px;
    left: -10px;
    transform: translateX(-100%);
    /*
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(calc(-100% + 3px));
    padding: 0 5px 1px;
    border-radius: 7px 0 0 7px;
    background: var(--v-error-base);
    transition: 0.2s ease-in;
    color: #fff;
    z-index: -1;
    &:hover {
        transform: translateX(calc(-100% + 6px));
    }
    */
  }
}

.field {
  position: relative;
  padding-left: 85px;

  &__container {
    position: relative;
  }

  &__cost-container {
    display: flex;
  }

  &__title {
    margin-top: 15px;
    font-size: 12px;

    &_one-word {
      line-height: 300%;
    }
  }

  &__text {
    position: absolute;
    top: 50%;
    left: -15px;
    transform: translate(-100%, -50%);
    font-size: 12px;

    &--decor {
      left: -35px;

      &::before {
        content: '';
        display: block;
        height: 64px;
        width: 18px;
        border: 2px solid #4a55b0;
        border-right: none;
        position: absolute;
        top: 50%;
        right: -10px;
        transform: translate(100%, -50%);
      }
    }

    &--disabled {
      background-color: rgba(196, 196, 196, 0.1);
    }
  }

  &__cost-text {
    font-size: 12px;
    display: flex;
    margin: auto;
    padding-right: 25px;
    vertical-align: center;
  }
}

.split {
  display: flex;
  width: 100%;
  //padding-bottom: 30px;
  &__item {
    width: 50%;

    &_row {
      display: flex;
      flex: 0 0.5 auto;
    }
  }

  &__item &__content {
    padding: 20px 30px;
    height: 100%;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--v-accent-base);
      border-radius: 3px;
    }
  }

  &__item:not(:last-child) &__content {
    border-right: 1px solid #d6d6d6;
  }

  &__item:last-child &__content {
    padding-right: 0;
  }
}

.border-block {
  border: 1px solid #4a55b0;
  padding: 10px;
}

.loading {
  color: transparent !important;
  min-height: 0.8rem;
  pointer-events: none;
  position: relative;
}

.loading::after {
  animation: loading 0.5s infinite linear;
  background: 0 0;
  border: 2px solid #5755d9;
  border-radius: 50%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  left: 50%;
  margin-left: -0.4rem;
  margin-top: -0.4rem;
  opacity: 1;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  z-index: 1;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 950px) {
  .field {
    &__cost-container {
      flex-direction: column;
      width: 230px;
    }

    &__calendar {
      flex-direction: column;
      width: 100%;
    }

    &__cost-text {
      width: 100%;
      padding: 0 0 10px;
    }
  }
}
</style>
