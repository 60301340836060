<template lang="pug">
  v-dialog(
    v-model="dialog"
  )
    v-card.pa-2(flat dark elevation="0")
      v-img.img(:src="srcImg" :contain="true")
</template>

<script>
export default {
  name: 'popupImage',
  props: {
    srcImg: {
      type: ''
    }
  },
  watch: {
    srcImg: {
      handler(value) {
        if (value.length > 0) {
          this.dialog = true
        }
      },
      deep: true
    }
  },
  data() {
    return {
      dialog: false
    }
  }
}
</script>

<style scoped>
.img {
  height: 85vh;
  width: 59vw;
  display: block;
}
</style>
