<template lang="pug">
  highcharts(style='width: 100%; margin: 10px 0', :options='options', ref='chart')
</template>

<script>
export default {
  name: 'EfficiencyChart',
  props: {
    data: Array,
    sampleRate: Number
  },

  data() {
    return {
      chartValues: [],
      chartKeys: [],
    };
  },
  computed: {
    options() {
      return {
        chart: {
          type: 'spline'
        },
        title: {
          text: 'Охват'
        },
        xAxis: {
          categories: this.chartKeys,
          tickPositions: [12, 4, 10, 13, 14, 15]
        },
        yAxis: {
          title: {
            text: null,
          },
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: false
            },
            marker: {
              enabled: false
            },
            showInLegend: false
          },
        },
        series: this.chartValues
      }
    },
  },
  watch: {
    sampleRate(newVal) {
      const indexSeries = this.chartValues.findIndex(el => el.name === `Доля эфира: ${newVal}`)
      this.$refs.chart.chart.series.forEach((s) => {
        s.setState('inactive', true);
      });
      this.$refs.chart.chart.series[indexSeries].setState('hover', true);
    }
  },
  mounted() {
    this.chartData();
  },
  methods: {
    chartData() {
      let params = this.data.map(item => item.reach)
      let keys = []
      params.map(item => keys = Object.keys(item).sort((a, b) => {
        return a.localeCompare(b, undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      }));
      this.chartKeys = keys
      this.chartValues = this.data.map(el => {
        const sorted_reach_keys = Object.keys(el.reach).sort((a, b) => {
          return a.localeCompare(b, undefined, {
            numeric: true,
            sensitivity: 'base'
          })
        })
        let sorted_reach = {}
        for (const reach of sorted_reach_keys) {
          sorted_reach[reach] = el.reach[reach];
        }
        return {
          name: 'Доля эфира: ' + el.sampleRate.toString(),
          data: Object.values(sorted_reach).map(item => +(item * 100).toFixed(1))
        }
      }).sort((a, b) => a.name.split(' ').at(-1) - b.name.split(' ').at(-1))
    }
  }
};
</script>

<style scoped></style>
