<template lang="pug">
  .efficiency
    .d-flex.justify-end
      span {{getCampaignAudience}}
    div(v-if='efficiencyArray.length')
      .efficiency__row
        .efficiency__mark-block OTS: {{ efficiency && efficiency.ots ? formatNumber(efficiency.ots) :  formatNumber(efficiencyArray[0].ots) }}
        .efficiency__mark-block Рейтинг: {{ efficiency && efficiency.rating ? efficiency.rating.toFixed(1) : efficiencyArray[0].rating.toFixed(1) }}
        .efficiency__mark-block Бюджет: {{ formatNumber(calculateBudget) }}
      .efficiency__row
        efficiency-chart(:data='efficiencyArray' :sample-rate='efficiency.sampleRate')
      .efficiency__row.efficiency__slider
        v-slider(
          :tick-labels="getSampleRate"
          :min='0'
          :max='getSampleRate.length- 1'
          ticks='always'
          tick-size='4'
          @end='setEfficiencyData'
        )
    .efficiency__row.align-center
      v-btn(
        :loading='isLoading'
        color='accent'
        elevation='0'
        @click='calculateEfficiency'
      ) {{ efficiencyArray.length ? 'Обновить' : 'Рассчитать эффективность' }}
      tooltip(text='Подставить значение в Рейтинг(TRP) и бюджет')
        v-btn.ml-2(
          v-if='efficiencyArray.length && !visibleRatingTRP'
          color='accent'
          elevation='0'
          x-small
          height="36"
          @click="setRatingTRPAndBudget"
        )
          v-icon mdi-check
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {formatRquestStatsData} from '@/mixins/date_mixin';
import loading_mixin from '@/mixins/loading_mixin';
import EfficiencyChart from './efficiencyChart';
import {GET_CAMPAIGN_STATS, GET_CBU_SAMPLE_RATE} from '@/store/const/campaign';
import Tooltip from '@/components/tooltip'

export default {
  name: 'CalculateEfficiency',
  components: {EfficiencyChart, Tooltip},
  mixins: [formatRquestStatsData, loading_mixin],
  data() {
    return {
      efficiency: {},
      efficiencyArray: [],
      totalBudget: 0,
      token: '',
    };
  },
  props: {
    visibleRatingTRP: Boolean
  },
  computed: {
    ...mapGetters('Campaign', ['getCampaign', 'getSelectedSurfaces', 'getEfficiencySampleRate']),
    ...mapGetters('Report', ['getReducedAnalytics']),
    getSampleRate() {
      return this.efficiencyArray.map(item => item.sampleRate).sort((a, b) => a - b)
    },
    getCampaignAudience() {
      if (this.getCampaign.audience.ageFrom && this.getCampaign.audience.ageTo && this.getCampaign.audience.sex.length) {
        const ageFrom = this.getCampaign.audience.ageFrom
        const ageTo = this.getCampaign.audience.ageTo
        let sex = ''
        let arrSex = []
        this.getCampaign.audience.sex.forEach((s) => {
          if (s === 'male') {
            arrSex.push('М')
          } else if (s === 'female') {
            arrSex.push('Ж')
          }
        });
        sex = arrSex.join('')
        return `ЦА: ${sex} ${ageFrom}-${ageTo} BC`
      }
    },
    calculateBudget() {
      let multiplier = this.efficiency.sampleRate ? this.efficiency.sampleRate / 100 : 0.025;
      return this.getReducedAnalytics(this.getCampaign.raisingCof) * multiplier
    }
  },
  methods: {
    ...mapActions('Campaign', [GET_CAMPAIGN_STATS, GET_CBU_SAMPLE_RATE]),
    setLoadingActions() {
      this.actions = [
        GET_CAMPAIGN_STATS
      ]
    },
    async calculateEfficiency() {
      let data = this.formatData(this.getCampaign, this.getSelectedSurfaces);
      if (data.periodFrom !== 'Invalid Date') {
        data.periodFrom = this.$moment(this.getCampaign.periodFrom).subtract(1, 'years').format('YYYY-MM-DD')
        data.periodTo = this.$moment(this.getCampaign.periodTo).subtract(1, 'years').format('YYYY-MM-DD')
        data.userKey = 'dspradar'
        const updated_data = {...data};
        delete updated_data.city;
      }
      await this.GET_CBU_SAMPLE_RATE(data)
      this.efficiencyArray = this.getEfficiencySampleRate
      if (!this.efficiencyArray.length) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Нет данных',
        });
      }

    },
    setEfficiencyData(value) {
      const sampleRate = this.getSampleRate.find((el, idx) => idx === value)
      this.efficiency = this.efficiencyArray.find(item => item.sampleRate === sampleRate)
    },
    setRatingTRPAndBudget() {
      this.$emit('getTotalBudget', this.calculateBudget);
      this.efficiency && this.efficiency.rating
        ? this.$emit('getRatingTRP', {ratingTRP: this.efficiency.rating.toFixed(1)})
        : this.$emit('getRatingTRP', {ratingTRP: this.efficiencyArray[0].rating.toFixed(1)})
    }
  },
};
</script>

<style scoped lang="scss">
.efficiency {
  display: flex;
  flex-direction: column;

  &__slider {
    position: relative;
  }

  &__row {
    display: flex;
    margin-top: 1em;
    justify-content: center;
  }

  &__mark-block {
    font-size: 14px;
    white-space: nowrap;
    line-height: 150%;
    margin-left: 1em;
    margin-right: 1em;
    padding: 5px 10px;
    border: 1px solid var(--v-accent-base);
    border-radius: 4px;
  }
}
</style>
