<template lang="pug">
  v-row
    v-dialog(
      v-model='dialog',
      persistent,
      max-width='800px',
      @keydown.27='$emit("onClose")',
      @click:outside='$emit("onClose")'
    )
      v-card
        v-card-title.mb-5
          span Расписание
        v-card-text
          div
            v-btn.mx-2(outlined, color='accent', @click='toggleAll') Выбрать/отменить все
            v-btn.mx-2(outlined, color='accent', @click='toggleDay') День
            v-btn.mx-2(outlined, color='accent', @click='toggleNight') Ночь
            v-btn.mx-2(outlined, color='accent', @click='togglePrimeTime') Прайм-тайм
          br
          div
            div
              table.days-table
                tbody
                  tr(v-for='(day, idx) in weekDays')
                    td(style='border: none')
                      v-checkbox(
                        v-model="daysValues[idx]"
                        dense,
                        hide-details,
                        :label='day.label'
                        color='accent',
                        :style='{ padding: 0, margin: 0 }',
                        @change="toggleDayHours(idx)"
                      )
                    td.hours(v-for='hour in dayHours')
                      v-checkbox(
                        v-model='weekDays[idx].hours[hour]',
                        dense,
                        hide-details,
                        fluid,
                        :background-color='weekDays[idx].hours[hour] ? "#88db70" : "#afcaaa"',
                        color='#2f2d2d',
                        on-icon='mdi-plus',
                        off-icon='mdi-minus',
                        :style='{ padding: 0, margin: 0 }',
                      )
                  tr
                    td(style='border: none')
                    td(v-for='hour in dayHours' style='border: none')
                      v-checkbox(
                        v-model="hoursValues[hour]"
                        dense,
                        hide-details,
                        fluid,
                        color='accent',
                        :style='{ padding: 0, margin: 0 }',
                        @change="toggleHourDays(hour)"
                      )
                      div {{ hour }}
              v-row.container.mt-4
                v-btn.mr-2(color='accent', elevation='0', @click='submit') Готово
                v-btn(color='secondary', elevation='0', @click='$emit("onClose")') Отмена

</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import {SET_CALENDAR_DATA} from '@/store/const/campaign';

export default {
  name: 'Calendar',
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      toggle: {
        all: false,
        day: false,
        night: false,
        primeTime: false,
      },
      daysValues: {},
      hoursValues: {},
      dayHours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      weekDays: [
        {
          label: 'пн',
          name: 'monday',
          hours: [],
        },
        {
          label: 'вт',
          name: 'tuesday',
          hours: [],
        },
        {
          label: 'ср',
          name: 'wednesday',
          hours: [],
        },
        {
          label: 'чт',
          name: 'thursday',
          hours: [],
        },
        {
          label: 'пт',
          name: 'friday',
          hours: [],
        },
        {
          label: 'сб',
          name: 'saturday',
          hours: [],
        },
        {
          label: 'вс',
          name: 'sunday',
          hours: [],
        },
      ],
    };
  },
  watch: {
    weekDays: {
      handler(days) {
        this.setDaysAndHours(days)
      },
      deep: true
    },
    getWeekDays: {
      handler(days) {
        if (days) {
          this.setWeekDays();
        }
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters('Campaign', ['getWeekDays', 'getCampaign']),
  },
  mounted() {
    if (this.getWeekDays) {
      this.setWeekDays();
    } else {
      this.toggleAll();
    }
  },
  methods: {
    ...mapMutations('Campaign', [SET_CALENDAR_DATA]),
    setWeekDays() {
      if (this.getWeekDays) {
        Object.keys(this.getWeekDays).forEach((day) => {
          this.weekDays.forEach((i, key) => {
            if (day === i.name && this.getWeekDays[day].hours && this.getWeekDays[day].hours.length > 0) {
              this.getWeekDays[day].hours.forEach(hour => {
                this.weekDays[key].hours[hour.from.hour] = true
              })
            }
          });
        });
      }
      this.setDaysAndHours(this.weekDays)
    },
    toggleAll() {
      this.clearToggle('all')
      for (let i = 0; i < this.weekDays.length; i++) {
        this.weekDays[i].hours = this.dayHours.map(hour => !this.toggle.all)
      }
      this.toggle.all = !this.toggle.all
    },
    toggleDay() {
      this.clearToggle('day')
      for (let i = 0; i < this.weekDays.length; i++) {
        this.weekDays[i].hours = this.dayHours.map(hour => {
          if (hour >= 7 && hour <= 19) {
            return !this.toggle.day
          }
        })
      }
      this.toggle.day = !this.toggle.day
    },
    toggleNight() {
      this.clearToggle('night')
      for (let i = 0; i < this.weekDays.length; i++) {
        this.weekDays[i].hours = this.dayHours.map(hour => {
          if ((hour >= 21 && hour <= 24) || (hour >= 0 && hour <= 3)) {
            return !this.toggle.night
          }
        })
      }
      this.toggle.night = !this.toggle.night
    },
    togglePrimeTime() {
      this.clearToggle('primeTime')
      for (let i = 0; i < this.weekDays.length; i++) {
        this.weekDays[i].hours = this.dayHours.map(hour => {
          if (hour >= 18 && hour <= 24) {
            return !this.toggle.primeTime
          }
        })
      }
      this.toggle.primeTime = !this.toggle.primeTime
    },
    clearToggle(toggle) {
      Object.keys(this.toggle).forEach(key => {
        if (key !== toggle) this.toggle[key] = false
      })
    },
    toggleDayHours(day) {
      this.weekDays[day].hours = this.dayHours.map(hour => !!this.daysValues[day])
    },
    toggleHourDays(hour) {
      for (let i = 0; i < this.weekDays.length; i++) {
        this.weekDays[i].hours[hour] = this.hoursValues[hour]
        this.weekDays[i].hours = [...this.weekDays[i].hours]
      }
    },
    submit() {
      const week_days = {
        enabled: true
      };
      this.weekDays.forEach(day => {
        week_days[day.name] = {
          hours: [],
        };
        day.hours.forEach((time, idx) => {
          if (time) {
            week_days[day.name].hours.push({
              from: {
                hour: idx,
                minute: parseInt('00'),
              },
              to: {
                hour: idx === 23 ? idx : idx + 1,
                minute: idx === 23 ? 30 : parseInt('00'),
              },
            });
          }
        });
        week_days[day.name].enabled = week_days[day.name].hours.length > 0
      });
      this.SET_CALENDAR_DATA(week_days);
      this.$emit('onClose');
    },
    setDaysAndHours(days) {
      const hours = [];
      days.forEach((day, idx) => {
        this.daysValues[idx] = day.hours.filter(i => i === true).length === this.dayHours.length;
        day.hours.forEach((item, i) => {
          if (item) hours.push(i)
          this.hoursValues[i] = hours.filter(h => h === i).length === this.weekDays.length
        })
      })
    },
  },
};
</script>

<style lang="scss">
table.days-table {
  border-collapse: collapse;

  td {
    text-align: center;
    border: 2px solid whitesmoke;

    &.hours.active {
      background-color: #88db70;
    }

    &.hours.inactive {
      background-color: #afcaaa;
    }
  }

  .day,
  .hour {
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .weekEnd {
    background: #ef7070;
  }

  .v-input--selection-controls {
    margin: 0;

    &__input {
      margin-right: 0 !important;
    }
  }
}
</style>
