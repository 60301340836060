<template lang="pug">
  div.d-flex.align-center.justify-center
    img.pl-6(src='@/assets/icons/excel.svg', alt='excel')
    .action_link.pl-2.pointer(@click="exportSelected") Скачать
</template>

<script>
import * as XLSX from 'xlsx/xlsx.mjs';

export default {
  name: 'export_excel',
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    exportSelected() {
      let sheet = XLSX.utils.json_to_sheet(this.data);
      let book = XLSX.utils.book_new();
      book.SheetNames.push('page-1')
      book.Sheets['page-1'] = sheet
      return XLSX.writeFile(book, this.filename + ' ' + new Date().toLocaleString('ru') + '.xlsx');
    },
  }
}
</script>

<style scoped>
.action_link {
  color: #4A55B0;
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
}
</style>